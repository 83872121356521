import React from 'react';

class ScrollingText extends React.Component {


  render(){

    return (
      
      <div class="border radius-lg padding-sm container margin-top-md text-nowrap overflow-hidden">
        <h4 class="scrolling-text">Alive & Well &nbsp; 良い &nbsp; Timeless &nbsp; タイムレス &nbsp; Contemporary &nbsp; モダン  &nbsp; Alive & Well &nbsp; 良い &nbsp; Timeless &nbsp; タイムレス &nbsp; Contemporary &nbsp; モダン</h4>
      </div>

    );

  }

}


export default ScrollingText;
