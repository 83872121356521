import React from 'react';

class Discord extends React.Component {


  render(){

    return (

      <svg class="margin-right-xs"  width="49" height="37" viewBox="0 0 49 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.134 3.23955C38.0747 1.83579 34.794 0.801558 31.3638 0.209211C31.3013 0.197779 31.2389 0.226348 31.2067 0.283489C30.7848 1.03392 30.3175 2.01292 29.9902 2.7824C26.3008 2.23007 22.6304 2.23007 19.0166 2.7824C18.6893 1.99581 18.205 1.03392 17.7811 0.283489C17.749 0.228255 17.6866 0.199685 17.6241 0.209211C14.1958 0.799665 10.9151 1.8339 7.85387 3.23955C7.82737 3.25098 7.80465 3.27004 7.78958 3.29479C1.56678 12.5915 -0.1379 21.6597 0.69836 30.6155C0.702144 30.6593 0.72674 30.7012 0.760796 30.7279C4.86642 33.7429 8.84341 35.5734 12.7466 36.7866C12.809 36.8057 12.8752 36.7828 12.915 36.7314C13.8383 35.4705 14.6613 34.1411 15.367 32.743C15.4086 32.6611 15.3688 32.564 15.2837 32.5316C13.9783 32.0364 12.7352 31.4326 11.5395 30.7469C11.4449 30.6917 11.4373 30.5564 11.5243 30.4917C11.7759 30.3031 12.0276 30.1069 12.2679 29.9088C12.3114 29.8727 12.372 29.865 12.4231 29.8879C20.2786 33.4744 28.7831 33.4744 36.5459 29.8879C36.597 29.8631 36.6576 29.8708 36.703 29.9069C36.9433 30.105 37.1949 30.3031 37.4484 30.4917C37.5354 30.5564 37.5298 30.6917 37.4352 30.7469C36.2394 31.4459 34.9964 32.0364 33.689 32.5297C33.6039 32.5621 33.5661 32.6611 33.6077 32.743C34.3285 34.1391 35.1515 35.4686 36.0578 36.7295C36.0956 36.7828 36.1637 36.8057 36.2262 36.7866C40.1483 35.5734 44.1252 33.7429 48.2309 30.7279C48.2668 30.7012 48.2895 30.6612 48.2933 30.6174C49.2942 20.2635 46.617 11.2697 41.1964 3.29668C41.1832 3.27004 41.1605 3.25098 41.134 3.23955ZM16.54 25.1624C14.175 25.1624 12.2263 22.9911 12.2263 20.3245C12.2263 17.6579 14.1372 15.4867 16.54 15.4867C18.9617 15.4867 20.8916 17.677 20.8538 20.3245C20.8538 22.9911 18.9428 25.1624 16.54 25.1624ZM32.4895 25.1624C30.1245 25.1624 28.1758 22.9911 28.1758 20.3245C28.1758 17.6579 30.0867 15.4867 32.4895 15.4867C34.9113 15.4867 36.8411 17.677 36.8033 20.3245C36.8033 22.9911 34.9113 25.1624 32.4895 25.1624Z" fill="white"/>
      </svg>
    );

  }

}


export default Discord;
