import React from 'react';

class Twitter extends React.Component {


  render(){

    return (

      <svg class="margin-right-xs" width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1003 40.0001C33.2091 40.0001 43.1166 24.9935 43.1166 11.9838C43.1166 11.5619 43.1072 11.1307 43.0884 10.7088C45.0158 9.31501 46.679 7.5886 48 5.61069C46.205 6.40929 44.2993 6.93086 42.3478 7.15756C44.4026 5.92591 45.9411 3.99104 46.6781 1.71163C44.7451 2.85721 42.6312 3.66532 40.4269 4.10131C38.9417 2.52321 36.978 1.47832 34.8394 1.12819C32.7008 0.778063 30.5064 1.14219 28.5955 2.16428C26.6846 3.18638 25.1636 4.8095 24.2677 6.78271C23.3718 8.75593 23.1509 10.9693 23.6391 13.0807C19.725 12.8843 15.8959 11.8675 12.4 10.0963C8.90405 8.32508 5.81939 5.83896 3.34594 2.79913C2.0888 4.96658 1.70411 7.53138 2.27006 9.97227C2.83601 12.4132 4.31013 14.547 6.39281 15.9401C4.82926 15.8904 3.29995 15.4695 1.93125 14.7119V14.8338C1.92985 17.1084 2.7162 19.3133 4.15662 21.0736C5.59704 22.834 7.60265 24.0412 9.8325 24.4901C8.38411 24.8863 6.86396 24.9441 5.38969 24.6588C6.01891 26.615 7.24315 28.3259 8.89154 29.5528C10.5399 30.7796 12.5302 31.4613 14.5847 31.5026C11.0968 34.2423 6.78835 35.7283 2.35313 35.7213C1.56657 35.7201 0.780798 35.6719 0 35.5769C4.50571 38.4676 9.74706 40.0029 15.1003 40.0001Z" fill="white"/>
      </svg>
    );

  }

}


export default Twitter;
